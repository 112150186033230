import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IPrinterEmail {
    id: number;
    printerID: string;
    emailAddress: string;
}

export class PrinterEmail implements IPrinterEmail {

    constructor(data?: IPrinterEmail) {
        if (data) this.update(data);
    }

    update(data: IPrinterEmail): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    printerID: string = utils.emptyGuidValue;
    emailAddress: string = "";
}