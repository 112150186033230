import { render, staticRenderFns } from "./Printers.vue?vue&type=template&id=3145b327&scoped=true&"
import script from "./Printers.vue?vue&type=script&lang=ts&"
export * from "./Printers.vue?vue&type=script&lang=ts&"
import style0 from "./Printers.vue?vue&type=style&index=0&id=3145b327&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3145b327",
  null
  
)

export default component.exports