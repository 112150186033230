






































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/dialogues/ConfirmDialogue.vue";
import utils from "@/utilities/Utils";
import { Country, ICountry } from "@/model/Country";
import { ISaveResponse } from "@/model/ISaveResponse";
import { PrinterVM } from "@/model/Api/VM/PrinterVM";
import { IPrinterEmail, PrinterEmail } from "@/model/PrinterEmail";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        ConfirmDialogue
    } 
})

export default class PrinterDialogue extends Vue {

    async mounted(): Promise<void> { 
        this.loadDeliveryCountries();
    }

    //
    // -- properties
    //

    @Ref("printerForm") private readonly printerForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private printer: PrinterVM = new PrinterVM();
    private countries: Array<Country> = [];
    private emails: Array<IPrinterEmail> = [];

    //
    // -- validations
    //

    private referenceErrorMessages: Array<string> = [];

    @Watch("printer.printer.reference")
    private async validateReference() {
        this.referenceErrorMessages = [];
        if (utils.isEmptyOrWhitespace(this.printer.printer.reference))  return;

        const response: { isInUse: boolean } = await apiClient.get(`/api/printer/referenceIsInUse?reference=${this.printer.printer.reference}&printerID=${this.printer.printer.id}`);
        if (response.isInUse) {
            this.referenceErrorMessages.push("Reference is already in use");
            return;
        }
    }

    //
    // -- methods
    //

    add(): void {
        this.reset();
        this.printer.printer.id = utils.emptyGuidValue;
        this.dialogueTitle = "Add Printer";
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit Printer";
        const printerData = await apiClient.get(`api/printer/Load?id=${id}`);
        this.printer.update(printerData.printer);
        this.emails.push(...printerData.emails)
        this.showDialogue = true;
    }

    async loadDeliveryCountries(): Promise<void> {
        const responseData = await apiClient.get("api/country/deliveryCountries");
        this.countries.push(...responseData.map((c: ICountry) => new Country(c)));
    }

    private async addEmail() {
        this.emails.push(new PrinterEmail());
    }

    private async removeEmail(email: PrinterEmail) {
        for(var i = 0; i < this.emails.length; i++) {
            if(this.emails[i].id === email.id) {
                this.emails.splice(i, 1);
                break;
            }
        }
    }

    private reset() {
        utils.resetObject(this.printer);
        this.emails = [];
        this.printerForm?.resetValidation();
    }

    private async save() {
        await this.validateReference();
        const isValid = this.printerForm.validate() && this.referenceErrorMessages.length === 0;
        if (!isValid) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        const postData = {
            printer: this.printer,
            emails: this.emails
        }

        const response: ISaveResponse = await apiClient.post("/api/printer/save", postData, "printer-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
        
    }
}
