






























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import PrinterDialogue from "@/vue/components/dialogues/PrinterDialogue.vue";
import utils from "@/utilities/Utils";
import { PrinterVM, IPrinterVM } from "@/model/Api/VM/PrinterVM";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { StateChanger } from "vue-infinite-loading";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        PrinterDialogue
    }
})

export default class Printers extends Vue {
    
    //
    // -- properties
    //

    private printerHeaders = [
        { text: "Ref", value: "printer.reference", sortable: true },
        { text: "Name", value: "printer.name", sortable: true },
        { text: "Address", value: "printer.address1", sortable: false },
        { text: "Country", value: "country", sortable: true },
        { text: "Emails", value: "hasEmails", align: "center", sortable: true }
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new SearchParameters();
    private printerList: Array<PrinterVM> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    addNew(): void {
        const dialog: PrinterDialogue = this.$refs.printerDialogue as PrinterDialogue;
        dialog.add();
    }

    edit(printer: IPrinterVM): void {
        const dialog: PrinterDialogue = this.$refs.printerDialogue as PrinterDialogue;
        dialog.edit(printer.printer.id);
    }

    refreshSearch(): void {
        this.printerList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/printer/search", this.searchParameters);
        if (response.list.length) {
            this.printerList.push(...response.list.map((p: IPrinterVM) => new PrinterVM(p)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }
}
